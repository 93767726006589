@import '../../node_modules/video-react/dist/video-react.css'; 

.main {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
  color: white !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
  color: white !important;
}

.app .ql-editor {
  min-height: 18em !important;
}

.ql-editor {
  overflow-y: scroll;
  resize: vertical;
}

.app {
  margin: 1rem 4rem;
}

.card-thumb{
  width: 80px;
  height: 120px;
}

.tableContainer {
  width: 100%;
}

.actionsCell {
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
}

.tableHeadCell {
  padding: 18px;
  text-align: left;
}

.tableCell {
  padding: 16px;
}

.customer {
  display: flex;
  align-items: center;
}

.hiddenForm {
  display: none;
}